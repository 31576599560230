import React, { useEffect, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { fetchFromApi } from "../utils/api";
import styles from './Upload.module.css';
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as zip from "@zip.js/zip.js";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Spinner from 'react-bootstrap/Spinner';
import UploadDropZone from "../components/UploadDropZone";
import TeamNameInput from "../components/TeamNameInput";
import EventNameInput from "../components/EventNameInput";
import { useNavigate } from 'react-router-dom'
import { Buffer } from 'buffer';
import { DemoFile, Player } from "demofile";
import { getShortPlayerName } from "../utils/teams";
import { v4 as uuidv4 } from 'uuid';
import { post_usage } from "../utils/usage";
import Upload from "./Upload";
import { useLocation } from 'react-router-dom';

interface UploadAPIResponse {
  upload_id: string;
  url: string;
  date: string;
  time: string;
  teamA_name: string;
  teamB_name: string;
  teamA_key: string;
  teamB_key: string;
  ext: string;
  content_type: string;
}

interface Players {
  [key: string]: string;
}

function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

const Upload2 = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [demoSizeMb, setDemoSizeMb] = useState<number>(0);
  const [uploadId, setUploadId] = useState<string>("");
  const [matchId, setMatchId] = useState<string | undefined>(undefined);
  const [date, setDate] = useState<Date>(new Date(Date.now()));
  const [time, setTime] = useState<string>("12:00");
  const [teamA, setTeamA] = useState<string>("");
  const [teamB, setTeamB] = useState<string>("");
  const [eventName, setEventName] = useState<string>("");
  const [statsUrl, setStatsUrl] = useState<string>("");
  const [fileErrorMessage, setFileErrorMessage] = useState<string>("");
  const [formErrorMessage, setFormErrorMessage] = useState<string>("");
  const [statusErrorMessage, setStatusErrorMessage] = useState<string>("");
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("NEW");
  const [visibility, setVisibility] = useState("UNLISTED");
  const [submittingForm, setSubmittingForm] = useState(false);

  const [teamAPlayers, setTeamAPlayers] = useState<Players>({});
  const [teamBPlayers, setTeamBPlayers] = useState<Players>({});

  const teamANameField = useRef<HTMLInputElement>(null);
  const eventNameField = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const location = useLocation();
  const getUploadMethod = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('method') === 'simple' ? 'Simple' : 'Accelerated';
  };

  const handleFileChange = async (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      post_usage('upload_start');
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setTeamAPlayers({});
      setTeamBPlayers({});

      const ext = file.name.split('.').pop();
      if (ext === 'zip') {
        try {
          const reader = new zip.ZipReader(new zip.BlobReader(file));
          const entries = await reader.getEntries();
          if (entries.every(entry => entry.filename.split('.').pop() === 'dem')) {
            setFileErrorMessage("");
            const totalSize = entries.reduce((acc, entry) => acc + entry.uncompressedSize, 0) / 1024 / 1024;
            setDemoSizeMb(totalSize);
            startUpload(file);
          } else {  // if not, show error message
            setFileErrorMessage("Zip file must contain only .dem files");
            setSelectedFile(null);
          }
        } catch (err) {
          setFileErrorMessage("Could not read .zip file");
          setSelectedFile(null);
        }
      } else if (ext === 'bz2' || ext === 'gz') {
        setFileErrorMessage("");
        // approximate since we can't uncompress bz2 or gz in browser easily
        setDemoSizeMb((file.size * 1.5) / 1024 / 1024);
        startUpload(file);
      } else if (ext === 'dem') {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        setDemoSizeMb(file.size / 1024 / 1024);
        setFileErrorMessage("");
        setSelectedFile(file);
        startUpload(file);
      } else {
        setFileErrorMessage("File must be a .dem, .zip, .gz, or .bz2 file");
        setSelectedFile(null);
      }
    }
  };

  function truncatePlayerList(players: Players) {
    let playerNames = Object.keys(players).map((key) => players[key]);

    for (let i = 0; i < playerNames.length; i++) {
      playerNames[i] = getShortPlayerName(playerNames[i], Object.keys(players).map((key) => players[key]));
    }

    let playerNamesString = playerNames.join(",")
    let maxLength = containsUnicode(playerNamesString) ? 18 : 29;
    if (playerNamesString.length > maxLength) {
      playerNamesString = playerNamesString.slice(0, maxLength) + "...";
    }
    return playerNamesString;
  }

  function containsUnicode(str: string) {
    for (let i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) > 127) return true;
    }
    return false;
  }

  const onDateChanged = (date: Date) => {
    setDate(date);
  }

  const onTimeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  }

  // validate form
  const validateForm = () => {
    return teamA.length > 0 && teamB.length > 0 && (visibility !== 'PUBLIC' || (eventName.length > 0));
  }

  const uploadDemoLegacy = (file: File) => {
    if (!validateForm()) {
      console.log('Legacy upload method');
      if (fileErrorMessage.length !== 0) {
        setFormErrorMessage("Invalid file!");
      }
      setUploadStatus("INITIALIZING");
      const fileName = file.name;
      const fileParts = fileName.split('.');
      const fileExtension = fileParts.length > 2 && fileParts.slice(-2).join('.') === 'tar.bz2' ? 'tar.bz2' : fileParts.pop();
      const data = {
        upload_id: uuidv4(),
        ext: fileExtension,
      };

      console.log('uploading file: ', data);

      fetchFromApi(`/upload_cs2`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then((response) => response.json())
        .then((data: UploadAPIResponse) => {
          setUploadId(data.upload_id);
          const xhr = new XMLHttpRequest();
          xhr.open('PUT', data.url);
          xhr.setRequestHeader('Content-Type', data.content_type);

          // Track progress
          xhr.upload.addEventListener('progress', (e) => {
            if (e.lengthComputable) {
              const percentage = Math.round((e.loaded / e.total) * 100);
              if (percentage > 0) {
                setUploadStatus("UPLOADING");
              }
              setProgress(percentage);
            }
            if (e.loaded === e.total) {
              setUploadStatus("QUEUED");
            }
          });

          xhr.send(file);
        })
        .catch(error => {
          console.log("Error uploading file: ", error);
        });
    }
  };

  interface UploadPartResult {
    ETag: string;
    PartNumber: number;
  }

  const uploadDemo = (file: File) => {
    const PART_SIZE = 10 * 1024 * 1024; // 10MB per part
    const MAX_PARALLEL_UPLOADS = 3; // Limit to 3 parallel uploads
    if (!validateForm()) {
      if (fileErrorMessage.length !== 0) {
        setFormErrorMessage("Invalid file!");
      }
      setUploadStatus("INITIALIZING");

      const fileName = file.name;
      const fileParts = fileName.split('.');
      const fileExtension = fileParts.length > 2 && fileParts.slice(-2).join('.') === 'tar.bz2' ? 'tar.bz2' : fileParts.pop();
      const data = {
        upload_id: uuidv4(),
        ext: fileExtension,
        part_count: Math.ceil(file.size / PART_SIZE) // Divide file into chunks
      };

      fetchFromApi(`/upload_cs2_multipart`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then((response) => response.json())
        .then(async (data) => {
          setUploadId(data.upload_id);
          const { urls, multipart_upload_id } = data;

          // Array to store individual part progress
          const partProgress = new Array(urls.length).fill(0);
          const uploadParts: UploadPartResult[] = [];

          // Function to upload a single part
          const uploadPart = async (url: string, chunk: Blob, i: number): Promise<UploadPartResult> => {
            const urlWithContentType = `${url}&content-type=${data.content_type}`;
            
            return new Promise((resolve, reject) => {
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', urlWithContentType);

              xhr.upload.addEventListener('progress', (e) => {
                if (e.lengthComputable) {
                  // Calculate progress for this part
                  const percentage = Math.round((e.loaded / e.total) * 100);
                  partProgress[i] = percentage;
                  // Calculate overall progress
                  const totalProgress = Math.round(partProgress.reduce((acc, curr) => acc + curr, 0) / urls.length);
                  setProgress(totalProgress);
                  setUploadStatus(totalProgress < 100 ? "UPLOADING" : "QUEUED");
                }
              });

              xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    const etag = xhr.getResponseHeader('ETag');
                    resolve({
                      ETag: etag ? etag.replaceAll('"', '') : '',
                      PartNumber: i + 1
                    });
                  } else {
                    reject(new Error(`Failed to upload part ${i + 1}`));
                  }
                }
              };

              xhr.send(chunk);
            });
          };

          // Function to control the number of parallel uploads
          const uploadPartsInBatches = async () => {
            for (let i = 0; i < urls.length; i += MAX_PARALLEL_UPLOADS) {
              const chunkUploads: Promise<UploadPartResult>[] = [];
              for (let j = i; j < i + MAX_PARALLEL_UPLOADS && j < urls.length; j++) {
                const chunk = file.slice(j * PART_SIZE, (j + 1) * PART_SIZE);
                chunkUploads.push(uploadPart(urls[j], chunk, j));
              }
              // Wait for the current batch of uploads to complete
              const batchResults: UploadPartResult[] = await Promise.all(chunkUploads);
              uploadParts.push(...batchResults);
            }
          };

          try {
            await uploadPartsInBatches();

            const completeData = {
              upload_id: data.upload_id,
              multipart_upload_id: multipart_upload_id,
              parts: uploadParts,
              ext: fileExtension
            };

            fetchFromApi(`/upload_cs2_multipart_complete`, {
              method: 'POST',
              body: JSON.stringify(completeData),
              headers: {
                'Content-Type': 'application/json'
              }
            })
              .then(response => response.json())
              .then(completeResponse => {
                console.log("Upload completed successfully!", completeResponse);
              })
              .catch(error => {
                console.error("Error completing upload: ", error);
              });
          } catch (error) {
            console.error("Error uploading parts: ", error);
          }
        })
        .catch(error => {
          console.error("Error initiating upload: ", error);
        });
    }
  };

  useEffect(() => {
    if (visibility === 'PUBLIC') {
      eventNameField.current?.focus();
    }
  }, [visibility]);

  useEffect(() => {
    if (selectedFile) {
      teamANameField.current?.focus();
    }
  }, [selectedFile]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (uploadStatus === 'QUEUED' || uploadStatus === 'PARSING' || uploadStatus === 'PROCESSING') {
      intervalId = setInterval(() => {
        fetchFromApi(`/upload_status?match_id=${uploadId}`)
          .then(res => res.json())
          .then(data => {
            if (data.status !== 'NEW') {
              if (uploadStatus !== 'PARSING' && data.status === 'PARSING') {
                setProgress(0);
              }
              if (data.status === 'WAITING') {
                console.log('WAITING', data);
                setProgress(data.progress * 100);
                setTeamA(data.teamA_name || '');
                setTeamB(data.teamB_name || '');
                setTeamAPlayers(data.teamA_parsed_players || {});
                setTeamBPlayers(data.teamB_parsed_players || {});
              }
              if (data.status === 'PROCESSING') {
                if (data.final_match_id.length > 0) {
                  setMatchId(data.final_match_id);
                }
                setProgress(data.progress * 100);
              }
              if (data.status === 'DONE') {
                setProgress(100);
              }
              if (data.status === 'RENAMING') {
                setUploadStatus('PROCESSING')
              } else if (data.status === 'DONE') {
                setTimeout(() => {
                  setUploadStatus("DONE");
                }, 3000);
              } else {
                setUploadStatus(data.status);
              }
            }
            // handle the error status from the API
            if (data.error) {
              console.error(data.error);
              setStatusErrorMessage(data.error);
              clearInterval(intervalId);
            }
          })
          .catch(error => console.error('Error fetching upload status: ', error));
      }, 3000);  // repeat every 3 seconds
    }

    // cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [uploadId, matchId, uploadStatus]);  // re-run when `uploadStatus` changes

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setSubmittingForm(true);
      setFormErrorMessage("");
      const data = {
        upload_id: uploadId,
        date: date.toISOString().split('T')[0],
        time: time,
        teamA_name: teamA,
        teamB_name: teamB,
        teamA_players: Object.keys(teamAPlayers),
        teamB_players: Object.keys(teamBPlayers),
        event_name: eventName,
        stats_url: statsUrl,
        visibility: visibility,
      };

      fetchFromApi(`/upload_cs2_postprocess`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then((response) => response.json())
        .then((data: UploadAPIResponse) => {
          console.log('upload_cs2_postprocess complete: ', data);
          setUploadStatus("PROCESSING");
        })
        .catch(error => {
          console.log("Error uploading file: ", error);
        });
    } else {
      setFormErrorMessage("Invalid form!");
    }
  }

  const startUpload = (file: File) => {
    if (getUploadMethod() === 'Accelerated') {
      uploadDemo(file);
    } else {
      uploadDemoLegacy(file);
    }
  };

  const progressTickTime = 1000; // milliseconds between progress increments
  const minProgressIncrement = 100 / ((0.75 * demoSizeMb * 1000) / progressTickTime); // minimum percentage to increment each tick
  const parsingCompleteThreshold = 95; // parsing considered complete at this progress
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    // fake parsing progress
    if (uploadStatus === "PARSING" && progress < parsingCompleteThreshold) {
      intervalId = setInterval(() => {
        // Create a random increment
        const randomIncrement = minProgressIncrement + (Math.random() * minProgressIncrement);
        setProgress(oldProgress => Math.min(oldProgress + randomIncrement, parsingCompleteThreshold));
      }, progressTickTime);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [uploadStatus, progress, minProgressIncrement]);

  const matchUrl = matchId ? `/match/${matchId}` : null;

  return (
    <div className={`container ${styles.uploadContainer}`}>
      <Container fluid>
        <Row>
          <Col>
            <Form>
              <Row className={styles.myrow}>
                <Col>
                  <UploadDropZone onChange={handleFileChange}>
                    <p>Drag 'n' drop <code>.dem</code>, <code>.bz2</code>, <code>.gz</code> or <code>.zip</code> file here</p>
                  </UploadDropZone>
                  {fileErrorMessage && <div className={styles.error}>{fileErrorMessage}</div>}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {uploadStatus !== 'NEW' && <>
          <Row className={styles.myrow}>
            <Col>
              <ToggleButtonGroup type="radio" name="status" value={uploadStatus} className={styles.statusGroup}>
                <ToggleButton variant="outline-primary" value="INITIALIZING" disabled className={`${styles.statusItem} ${styles.initializing} ${uploadStatus === "INITIALIZING" ? styles.active : styles.inactive}`}>
                  {uploadStatus === "INITIALIZING" && <Spinner animation="grow" size="sm" className={styles.spinnerInitializing} />}<span style={{ marginLeft: 6 }}>Initializing</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="UPLOADING" disabled className={`${styles.statusItem} ${styles.uploading} ${uploadStatus === "UPLOADING" ? styles.active : styles.inactive}`}>
                  {uploadStatus === "UPLOADING" && <Spinner animation="grow" size="sm" className={styles.spinnerUploading} />}<span style={{ marginLeft: 6 }}>File Transfer</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="QUEUED" disabled className={`${styles.statusItem} ${styles.queued} ${uploadStatus === "QUEUED" ? styles.active : styles.inactive}`}>
                  {uploadStatus === "QUEUED" && <Spinner animation="grow" size="sm" className={styles.spinnerQueued} />}<span style={{ marginLeft: 6 }}>Queued</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="PARSING" disabled className={`${styles.statusItem} ${styles.parsing} ${uploadStatus === "PARSING" ? styles.active : styles.inactive}`}>
                  {uploadStatus === "PARSING" && <Spinner animation="grow" size="sm" className={styles.spinnerParsing} />}<span style={{ marginLeft: 6 }}>Parsing</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="WAITING" disabled className={`${styles.statusItem} ${styles.waiting} ${uploadStatus === "WAITING" ? styles.active : styles.inactive}`}>
                  <span>Configure</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="PROCESSING" disabled className={`${styles.statusItem} ${styles.processing} ${uploadStatus === "PROCESSING" ? styles.active : styles.inactive}`}>
                  {uploadStatus === "PROCESSING" && <Spinner animation="grow" size="sm" className={styles.spinnerProcessing} />}<span style={{ marginLeft: 6 }}>Processing</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="DONE" disabled className={`${styles.statusItem} ${styles.done} ${uploadStatus === "DONE" ? styles.active : styles.inactive}`}>{uploadStatus === "DONE" && <i className="bi bi-check" style={{ color: '#0f0' }}></i>}Done</ToggleButton>
              </ToggleButtonGroup>
            </Col>
          </Row>
          {uploadStatus === 'ERROR' && statusErrorMessage.length > 0 &&
            <Row className={styles.myrow}>
              <Col>
                <div className={styles.error}>
                  {statusErrorMessage &&
                    statusErrorMessage.split('\n').map((item, key) => {
                      return <p key={key} className={styles.error}>{item}</p>
                    })}
                </div>
              </Col>
            </Row>
          }
          <Row className={styles.myrow}>
            <Col>
              <ProgressBar
                now={progress}
                label={
                  <>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <span>{Math.round(progress)}%</span>
                    </div>
                  </>
                }
                className={uploadStatus === "UPLOADING" ? "uploadProgressBar" : (uploadStatus === "PARSING" ? "parsingProgressBar" : "processingProgressBar")}
                animated={uploadStatus === "UPLOADING" || uploadStatus === "PARSING" || uploadStatus === "PROCESSING"}
              />
            </Col>
          </Row>
        </>}
        {(uploadStatus === "WAITING" || uploadStatus === "PROCESSING") && (<>
          <hr />
          <Form onSubmit={handleSubmit}>
            <Row className={`${styles.myrow} d-flex`}>
              <Col style={{ flex: '0 0 auto', width: '130px' }}>
                <Form.Group>
                  <Form.Label>Date</Form.Label>
                  <div>
                    <DatePicker
                      className={`match-datepicker dropdown-toggle btn btn-secondary btn-sm ${styles.dateButton}`}
                      dateFormat="yyyy-MM-dd"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      selected={date}
                      onChange={onDateChanged} />
                  </div>
                </Form.Group>
              </Col>
              <Col style={{ flex: '0 0 auto', width: '90px' }}>
                <Form.Group>
                  <Form.Label>Time</Form.Label>
                  <Form.Control type="text" value={time} onChange={onTimeChanged} className={styles.textInput} placeholder="hh:mm" />
                </Form.Group>
              </Col>
              <Col style={{ flexGrow: 1 }}>
                <Form.Group>
                  <Form.Label>
                    Team A
                    <span style={{ color: '#666', marginLeft: '10px' }}>
                      ({truncatePlayerList(teamAPlayers)})
                    </span>

                  </Form.Label>
                  <TeamNameInput className={styles.textInput} onChange={(value) => setTeamA(value)} value={teamA} disabled={uploadStatus !== "WAITING"} />
                </Form.Group>
              </Col>
              <Col style={{ flexGrow: 1 }}>
                <Form.Group>
                  <Form.Label>
                    Team B
                    <span style={{ color: '#666', marginLeft: '10px' }}>
                      ({truncatePlayerList(teamBPlayers)})
                    </span>

                  </Form.Label>
                  <TeamNameInput className={styles.textInput} onChange={(value) => setTeamB(value)} value={teamB} disabled={uploadStatus !== "WAITING"} />
                </Form.Group>
              </Col>
            </Row>
            {teamA.length > 0 && teamB.length > 0 && (
              <Row className={styles.myrow}>
                <Col>
                  <ToggleButtonGroup className={styles.visibilityGroup} type="radio" name="visibility" value={visibility} >
                    <ToggleButton onClick={() => setVisibility('PUBLIC')} variant="outline-primary" value="PUBLIC" className={`${visibility === 'PUBLIC' ? styles.selected : ''} ${styles.visibilityItem} ${styles.public}`} disabled={uploadStatus !== "WAITING"}>
                      <i className="bi bi-eye"></i> <span>Public</span><br /><span className={styles.visibilityExplanation}>For everyone</span>
                    </ToggleButton>
                    <ToggleButton onClick={() => setVisibility('UNLISTED')} variant="outline-primary" value="UNLISTED" className={`${visibility === 'UNLISTED' ? styles.selected : ''} ${styles.visibilityItem} ${styles.unlisted}`} disabled={uploadStatus !== "WAITING"}>
                      <i className="bi bi-link-45deg"></i> <span>Unlisted</span><br /><span className={styles.visibilityExplanation}>Anyone with a link</span>
                    </ToggleButton>
                    <ToggleButton onClick={() => setVisibility('PRIVATE')} variant="outline-primary" value="PRIVATE" className={`${visibility === 'PRIVATE' ? styles.selected : ''} ${styles.visibilityItem} ${styles.private}`} disabled={uploadStatus !== "WAITING"}>
                      <i className="bi bi-lock-fill"></i> <span>Private</span><br /><span className={styles.visibilityExplanation}>User / org only</span>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Col>
              </Row>
            )}
            {visibility === 'PUBLIC' && <>
              <Row className={`${styles.myrow} d-flex`}>
                <Col style={{ flexGrow: 1 }}>
                  <Form.Group>
                    <Form.Label>Event name</Form.Label>
                    <EventNameInput onChange={(value) => setEventName(value)} className={styles.textInput} userEventsOnly={true} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className={`${styles.myrow} d-flex`}>
                <Col style={{ flexGrow: 1 }}>
                  <Form.Group>
                    <Form.Label>External match URL<span style={{ color: "#666", marginLeft: '8px' }}>(optional)</span></Form.Label>
                    <Form.Control type="text" value={statsUrl} onChange={(e) => setStatsUrl(e.target.value)} className={styles.textInput} placeholder="https://mytournament.com/match/12345" />
                  </Form.Group>
                </Col>
              </Row>
            </>}
            {uploadStatus === 'WAITING' && <>
              <hr />
              <Row className={styles.myrow}>
                <Col>
                  {formErrorMessage && <div className={styles.error}>{formErrorMessage}</div>}
                  <Button variant="primary" type="submit" disabled={!validateForm()} className={styles.uploadButton}>
                    <>{submittingForm ? <Spinner animation="border" size="sm" style={{ marginRight: '4px', position: 'relative', top: '1px' }} /> : <i className="bi bi-box-arrow-up" style={{ paddingRight: 5 }}></i>} Save</>
                  </Button>
                </Col>
              </Row>
            </>
            }
          </Form>
        </>)}
        {uploadStatus === 'DONE' && matchUrl && <>
          <hr />
          <Row className={styles.myrow}>
            <Col>
              <Button className="btn-sm" style={{ width: '100%' }} onClick={() => {
                sessionStorage.removeItem('playlist');
                navigate(matchUrl);
              }}>
                <i className="bi bi-play-fill"></i>Replay
              </Button>
            </Col>
          </Row>
        </>}
        <hr />
        <Row>
          <Col className="d-flex justify-content-center" style={{ marginTop: 10 }}>
            <div className="feedback-text-wrapper" style={{ display: 'flex' }}>
              <span className="feedback-text" style={{ color: '#888' }}>
                Feedback?
              </span>
              <span className="feedback-text-arrow"><i className="bi bi-arrow-right"></i></span>
              <div className="discord-button" style={{ marginTop: 1 }}>
                <a href="https://discord.gg/FdxpTYwtrV" target="_blank" className="discord-button" rel="noreferrer">
                  <img src="/discord-icon.png" height={15}></img>
                  <span className="feedback-label">Discord</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Upload2;
